import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import HeroChevron from "../../components/hero/hero-chevron";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import ContactInfo from "../../components/contact-info/contact-info";
import SEO from "../../components/seo/seo";
import getHeroImgVariables from "../../helpers/getHeroImgVariables";
import Button from "../../components/custom-widgets/button";
import showMktoForm from "../../helpers/showMktoForm";
import BestBanksDefault from "../../components/best-banks/best-banks-default";
import TwoColumnSectionWithImage from "../../components/custom-widgets/two-column-section-with-image";

const CommercialRealEstateLoans = () => {
  const title = "Commercial Real Estate Financing & Loans";
  const description =
    "Learn how WaFd Bank is your one-stop shop for commercial real estate loans including construction loans, permanent financing, and interim loans for acquisition.";

  const imgData = useStaticQuery(graphql`
    query {
      imgVariableXXL: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-loans/hero-commercial-real-estate-12302022-XXL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-loans/hero-commercial-real-estate-12302022-XL.jpg"
        }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-loans/hero-commercial-real-estate-12302022-LG.jpg"
        }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-loans/hero-commercial-real-estate-12302022-MD.jpg"
        }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-loans/hero-commercial-real-estate-12302022-SM.jpg"
        }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-loans/hero-commercial-real-estate-12302022-XS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: {
          eq: "hero/commercial-banking/commercial-real-estate-loans/hero-commercial-real-estate-12302022-XXS.jpg"
        }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content: description
      },
      {
        name: "keywords",
        content: "Commercial financing"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/commercial-banking/commercial-real-estate-loans"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-commercial-real-estate-12302022.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "commercial-real-estate-loans-hero",
    chevronBgClass: "bg-info",
    ...getHeroImgVariables(imgData),
    altText: "Colleagues looking at an architectural model",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Helping You Meet Your Goals Since 1917"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-btn-1",
            class: "btn-white",
            onClick: () => showMktoForm(1067),
            type: "button",
            text: "Contact Us"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/commercial-banking",
      title: "Commercial Banking"
    },
    {
      id: 2,
      active: true,
      title: "Commercial Real Estate"
    }
  ];

  const FeaturesContent = () => {
    return (
      <>
        <h3 className="text-success font-weight-semi-bold">WaFd Bank is your one stop shop for:</h3>
        <ul id="cb-re-loans-shop-list">
          <li id="cb-re-loans-shop-list-item-1">Construction loans</li>
          <li id="cb-re-loans-shop-list-item-2">Permanent financing</li>
          <li id="cb-re-loans-shop-list-item-3">Interim loans for acquisition or repositioning</li>
        </ul>
        <h3 className="text-success font-weight-semi-bold">Let us work for you. We offer:</h3>
        <ul id="cb-re-loans-offer-list">
          <li id="cb-re-loans-offer-list-item-1">Flexible fees</li>
          <li id="cb-re-loans-offer-list-item-2">Competitive fixed and floating pricing</li>
          <li id="cb-re-loans-offer-list-item-3">Amortizations from 15 to 30 years</li>
          <li id="cb-re-loans-offer-list-item-4">Interest-only periods</li>
          <li id="cb-re-loans-offer-list-item-5">
            Thoughtful solutions to meet your ownership and partnership structures
          </li>
        </ul>
        <Button
          id={"cta-contact-us"}
          class={"btn-primary no-min-width w-100 w-sm-auto"}
          onClick={() => showMktoForm(1067)}
          showIcon={false}
          type={"button"}
          text={"Contact Us"}
        />
      </>
    );
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Commercial Real Estate Loans</h1>
        <h3 className="mb-0">
          Count on WaFd Bank for responsive, knowledgeable answers to your questions and fast, efficient turnaround. We
          are committed to building long-term relationships and delivering first-rate service that's tailored to meet
          your individual needs.
        </h3>
      </section>
      <TwoColumnSectionWithImage isGray={true} contentOnLeft={false}>
        {{
          image: (
            <StaticImage
              src="../../images/thumbnail-commercial-real-estate-12302022.jpg"
              alt="Businesswoman shaking hands with client across desk."
              placeholder="blurred"
              quality="100"
              className="mb-3"
            />
          ),
          content: <FeaturesContent />
        }}
      </TwoColumnSectionWithImage>
      <ContactInfo type="commercial" />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};
export default CommercialRealEstateLoans;
